import React, { useState,useEffect } from "react";
import "./App25.css";
const App25=()=>{
    const [city,setCity]=useState(null);
    const [sdata,setSdata]=useState("Lahore");
    const [search,setSearch]=useState("Lahore");
    useEffect(()=>{
        const fetchApi =async()=>{
        const url =`https://api.openweathermap.org/data/2.5/weather?q=${sdata}&units=metric&appid=b2db05e9b1faee710c051ae8cee650ec`;
            const response=await fetch(url);
            const resJson=await response.json();
            setCity(resJson.main);
        }
            fetchApi();
    },[sdata])
    return(
        <>
        <div className="box">
            <div className="inputData">
                <input type="search" className="inputFild" value={search} onChange={(event)=>{setSearch(event.target.value)}}/>
                <button className="btn" onClick={(()=>{setSdata(search)})}>search</button>
            </div>
            {!city ? (
                <p>No Data Found</p>
            ) : (
                <>
                <div className="info">
                    <div className="weather">
                        <i class="fa-solid fa-cloud"></i>
                    </div>
                    <h1 className="temp">{city.temp} <span className="temp-s">°C</span></h1>
                    <h2 className="location"><i className="fa-solid fa-street-view"></i>{sdata}</h2>
                    <div className="info-box">
                        <h3 className="info-title">Humidity:</h3>
                        <p className="info-desc">{city.humidity}%</p>
                    </div>
                    <div className="info-box">
                        <h3 className="info-title">Pressure:</h3>
                        <p className="info-desc">{city.pressure} hPa</p>
                    </div>
                </div>
            </>
            )

            }
        </div>
        </>
    );
};
export default App25;